<template>
  <div class="contact-container">
    <div class="container">
      <h2>Contact</h2>
      <div class="contact-content">
        <div class="contact-form-container">
          <form @submit.prevent="submitForm">
            <div class="form-group">
              <input type="text" v-model="name" placeholder="Name" required class="form-control" />
            </div>
            <div class="form-group">
              <input type="email" v-model="email" placeholder="Email" required class="form-control" />
            </div>
            <div class="form-group">
              <textarea v-model="message" placeholder="Message" required class="form-control"></textarea>
            </div>
            <button type="submit" class="btn-submit">Send</button>
          </form>
        </div>
        <div class="contact-info">
          <p>Email: aguirredalai6@gmail.com</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ContactPage',
  data() {
    return {
      name: '',
      email: '',
      message: '',
    };
  },
  methods: {
    async submitForm() {
      try {
        const response = await axios.post('https://hawkdata.tech/send', {
          name: this.name,
          email: this.email,
          message: this.message,
        });
        alert('Message sent: ' + response.data);
      } catch (error) {
        console.error(error);
        alert('There was an error sending your message.');
      }
    },
  },
};
</script>


<style scoped>
/* Contact Container */
.contact-container {
  background-color: #f9f7f6; 
  padding: 4rem 0;
}

.container {
  max-width: 800px;
  margin: 0 auto;
}

h2 {
  font-family: "Playfair Display", serif;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 2rem;
  color: #333;
}

/* Contact Content */
.contact-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-form-container {
  width: 100%;
  margin-bottom: 2rem;
}

.contact-info {
  width: 100%;
  text-align: center;
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-info p {
  margin: 0.5rem 0;
  font-size: 1rem;
  color: #555;
}

/* Form Styles */
.form-group {
  margin-bottom: 1.5rem;
}

.form-control {
  width: 100%;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 1rem;
}

.btn-submit {
  display: block;
  width: 100%;
  padding: 1rem;
  background-color: black;
  color: white;
  border: none;
  border-radius: 4px;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-submit:hover {
  background-color: #333;
}

/* Media Query for Smaller Screens */
@media (max-width: 768px) {
  .contact-content {
    flex-direction: column; 
    text-align: center;
  }

  .contact-info {
    margin-top: 2rem;
  }
}
</style>

import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './pages/HomePage.vue';
import AboutPage from './pages/AboutPage.vue';
import ServicesPage from './pages/ServicesPage.vue';
import PortfolioPage from './pages/PortfolioPage.vue';
import PricingPage from './pages/PricingPage.vue';
import BlogPage from './pages/BlogPage.vue';
import ContactPage from './pages/ContactPage.vue';
import FAQsPage from './pages/FAQsPage.vue';
import LegalPage from './pages/LegalPage.vue';

const routes = [
  { path: '/', component: HomePage },
  { path: '/about', component: AboutPage },
  { path: '/services', component: ServicesPage },
  { path: '/portfolio', component: PortfolioPage },
  { path: '/pricing', component: PricingPage },
  { path: '/blog', component: BlogPage },
  { path: '/contact', component: ContactPage },
  { path: '/faqs', component: FAQsPage },
  { path: '/legal', component: LegalPage },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;

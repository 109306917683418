<template>
  <main>
    <section class="hero">
      <div class="hero-content">
        <img 
          src="https://images.pexels.com/photos/3184292/pexels-photo-3184292.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
          alt="Hero 
 Image" 
          class="hero-image"
        />

        <div class="hero-text-container">
  <h1 class="hero-title">Unleash the Power of Data</h1>
  <p class="subtitle">Intelligent Web Scraping for Strategic Decisions</p>
  <router-link to="/servicios" class="cta-button">View Services</router-link>
</div>
</div>
</section>
<section class="gap">
</section>
<section class="services-container" v-if="services.length">
  <div class="container">
    <h2>Our Services</h2>
    <div class="row"> 
      <div class="col-md-6 mb-4" v-for="service in services" :key="service.id" @click="showModal(service)">
        <div class="service-card h-100">
          <img :src="service.icon" :alt="service.title" class="service-icon">
          <h3 class="service-title">{{ service.title }}</h3>
          <p class="service-description">{{ service.description }}</p>
        </div>
      </div>
    </div>
  </div>
</section>
    <ModalComponent v-if="showServiceModal" :serviceData="selectedService" @close="closeModal">
    </ModalComponent>
  </main>
</template>

<script>
import ModalComponent from './ModalComponent.vue';

export default {
  components: { ModalComponent },
  data() {
    return {
      heroImage: 'https://images.pexels.com/photos/3184292/pexels-photo-3184292.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
      showServiceModal: false,
      selectedService: null,
      services: [
        {
          id: 1,
          icon: 'https://images.pexels.com/photos/3183198/pexels-photo-3183198.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
          title: 'Custom Web Scraping',
          description: 'We transform your data challenges into tailor-made solutions. Our team of experts designs and implements web scraping strategies specifically for your needs, ensuring you get the precise information you need to drive your business forward.',
          steps: [
            'Analysis of your data needs and goals.',
            'Design of a scraper specifically for your data sources.',
            'Development and testing of the scraper to ensure accuracy.',
            'Implementation of the scraper and delivery of clean, structured data.',
            'Ongoing maintenance and updates as needed.',
          ],
        },
        {
          id: 2,
          icon: 'https://images.pexels.com/photos/669615/pexels-photo-669615.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
          title: 'Intelligent Data Extraction',
          description: 'We access hidden and structured data sources on the web. Our advanced data extraction technology allows you to gather information from websites, social media, databases, and more, to gain actionable insights and make informed decisions.',
          steps: [
            'Identification of relevant data sources.',
            'Development of extraction techniques to handle different formats.',
            'Validation and cleaning of extracted data.',
            'Analysis and transformation of data into useful formats.',
            'Delivery of clear reports and customized visualizations.',
          ],
        },
        {
          id: 3,
          icon: 'https://images.pexels.com/photos/3760067/pexels-photo-3760067.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
          title: 'Process Automation Consulting',
          description: 'We optimize your workflows with intelligent automation solutions.',
          steps: [
            'Analysis of your current processes and pain points.',
            'Identification of automation opportunities.',
            'Design and implementation of automated solutions.',
            'Rigorous testing to ensure efficiency and accuracy.',
            'Training and support for successful adoption.',
          ],
        },
        {
          id: 4,
          icon: 'https://images.pexels.com/photos/5335154/pexels-photo-5335154.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
          title: 'Monitorización de Precios y Competencia',
          description: 'Price and Competitor Monitoring',
          steps: [
            'Identification of your key competitors and products to monitor.',
            'Setup of custom alerts for price changes.',
            'Analysis of trends and patterns in competitor pricing.',
            'Detailed reports and visualizations for strategic decision-making.',
            'Continuous adaptation and optimization of the monitoring strategy.',
          ],
        },
      ],
    };
  },
  methods: {
    showModal(service) {
      this.showServiceModal = true;
      this.selectedService = service;
    },
    closeModal() {
      this.showServiceModal = false;
    }
  }
};
</script>

<style scoped>
/* General Styling */
body {
  font-family: "Montserrat", sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  background-color: #F8F4F1;
  color: #333;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Playfair Display", serif;
  font-weight: 300;
}

/* Section Headings */
h2 {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 2rem;
}

/* Container for Sections */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
}

/* Hero Section */
.hero {
  position: relative;
  background-image: v-bind(heroImage);
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-overlay 
 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

.hero-content {
  position: relative; /* Ensure content is on top of the overlay */
  z-index: 1;
  display: grid;                 /* Use CSS Grid for layout */
  grid-template-columns: 1fr 2fr; /* Image (1 part), Text (2 parts) */
  gap: 2rem;                    /* Add spacing between image and text */
  align-items: center;
}

.hero-text {
  color: white;
  text-align: left;
}

.hero-text h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  font-family: "Playfair Display", serif;
  font-weight: bold;
}

.subtitle {
  font-size: 1.2rem;
  line-height: 1.8;
  margin-bottom: 2rem;
}

.cta-button {
  font-family: "Montserrat", sans-serif;
  color: #333; /* Dark grey text */
  font-weight: bold;
  text-decoration: none;
  font-size: 1rem;
  position: relative;
}

.cta-button:hover {
  text-decoration: underline;
}

/* Services Section */
.services-container {
  background-color: #F8F4F1;
  padding: 4rem 0;
}

.service-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.service-card {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
}

.service-icon,
.portfolio-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 1rem;
}

.service-title,
.portfolio-title {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #333;
}

.service-description,
.portfolio-description {
  font-size: 1rem;
  color: #666;
}

/* Testimonials Section */
.testimonial-card {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-bottom: 2rem;
}

.testimonial-text {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: #555;
}

.testimonial-name {
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;
}

.testimonial-company {
  font-size: 1rem;
  color: #777;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .hero {
    flex-direction: column;
    text-align: center;
  }

  .hero-image {
    margin-right: 0;
    margin-bottom: 2rem;
  }

  .service-grid {
    grid-template-columns: 1fr;
  }
}

</style>


<template>
  <div>
    <section class="portfolio">
      <h2>Case Studies</h2>
      <div class="case-study" v-for="caseStudy in caseStudies" :key="caseStudy.id">
        <h3>{{ caseStudy.title }}</h3>
        <p>{{ caseStudy.description }}</p>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: 'PortfolioPage',
  data() {
    return {
      caseStudies: [
        { id: 1, title: 'Project A', description: 'Description of project A.' },
        { id: 2, title: 'Project B', description: 'Description of project B.' },
        // Add more case studies as needed
      ],
    };
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>

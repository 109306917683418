<template>
  <div>
    <section class="blog">
      <h2>Blog</h2>
      <div class="blog-post" v-for="post in blogPosts" :key="post.id">
        <h3>{{ post.title }}</h3>
        <p>{{ post.excerpt }}</p>
        <router-link :to="`/blog/${post.id}`">Read More</router-link>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'BlogPage',
  data() {
    return {
      blogPosts: [
        { id: 1, title: 'What is Web Scraping?', excerpt: 'An introduction to web scraping...' },
        { id: 2, title: 'Top 5 Web Scraping Tools', excerpt: 'A review of the top 5 web scraping tools...' },
        // Add more blog posts as needed
      ],
    };
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>

<template>
  <div class="services-container">
    <h2>Our Services</h2>

    <div class="services-row">
      <div v-for="service in services" :key="service.id" class="service-column">
        <img :src="service.icon" :alt="service.title" class="service-icon">
        <h3 class="service-title">{{ service.title }}</h3>
        <p class="service-description">{{ service.description }}</p>
        <h4>Our Process:</h4>
        <ol class="service-steps">
          <li v-for="step in service.steps" :key="step">{{ step }}</li>
        </ol>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showModalData:
 null,
 services: [
        {
          id: 1,
          icon: 'https://images.pexels.com/photos/3183198/pexels-photo-3183198.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
          title: 'Custom Web Scraping',
          description: 'We transform your data challenges into tailor-made solutions. Our team of experts designs and implements web scraping strategies specifically for your needs, ensuring you get the precise information you need to drive your business forward.',
          steps: [
            'Analysis of your data needs and goals.',
            'Design of a scraper specifically for your data sources.',
            'Development and testing of the scraper to ensure accuracy.',
            'Implementation of the scraper and delivery of clean, structured data.',
            'Ongoing maintenance and updates as needed.',
          ],
        },
        {
          id: 2,
          icon: 'https://images.pexels.com/photos/669615/pexels-photo-669615.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
          title: 'Intelligent Data Extraction',
          description: 'We access hidden and structured data sources on the web. Our advanced data extraction technology allows you to gather information from websites, social media, databases, and more, to gain actionable insights and make informed decisions.',
          steps: [
            'Identification of relevant data sources.',
            'Development of extraction techniques to handle different formats.',
            'Validation and cleaning of extracted data.',
            'Analysis and transformation of data into useful formats.',
            'Delivery of clear reports and customized visualizations.',
          ],
        },
        {
          id: 3,
          icon: 'https://images.pexels.com/photos/3760067/pexels-photo-3760067.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
          title: 'Process Automation Consulting',
          description: 'We optimize your workflows with intelligent automation solutions.',
          steps: [
            'Analysis of your current processes and pain points.',
            'Identification of automation opportunities.',
            'Design and implementation of automated solutions.',
            'Rigorous testing to ensure efficiency and accuracy.',
            'Training and support for successful adoption.',
          ],
        },
        {
          id: 4,
          icon: 'https://images.pexels.com/photos/5335154/pexels-photo-5335154.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
          title: 'Price and Competitor Monitoring',
          description: 'Price and Competitor Monitoring',
          steps: [
            'Identification of your key competitors and products to monitor.',
            'Setup of custom alerts for price changes.',
            'Analysis of trends and patterns in competitor pricing.',
            'Detailed reports and visualizations for strategic decision-making.',
            'Continuous adaptation and optimization of the monitoring strategy.',
          ],
        },
      ],
    };
  },
  methods: {
    showModal(service) {
      this.showServiceModal = true;
      this.selectedService = service;
    },
    closeModal() {
      this.showServiceModal = false;
    }
  }
};
</script>
<style scoped>
/* General Styles */
.services-container {
  background-color: #F8F4F1; 
  padding: 4rem 0;
  text-align: center;
}

h2 {
  font-size: 2.8rem;
  margin-bottom: 3rem;
}

/* Service Grid */
.services-row {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping to next line on smaller screens */
  gap: 2rem; /* Space between columns */
}

.service-column {
  flex: 1 1 280px; /* At least 280px wide, but flexible */
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: left; /* Align text to the left within the column */
}

.service-icon {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 1rem;
}

.service-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.service-description {
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

/* Process Steps */
.service-steps {
  list-style: none;
  padding: 0;
  counter-reset: step; /* For custom numbering */
}

.service-steps li {
  position: relative;
  padding-left: 2.5rem;
  margin-bottom: 0.5rem;
}

.service-steps li::before {
  content: counter(step) ". ";
  counter-increment: step;
  position: absolute;
  left: 0;
  font-weight: bold;
}

/* Color Palette (Inspired by Christina Vanessa) */
.services-container h2 {
  color: #888; /* Muted header color */
}

.service-title {
  color: #333; /* Darker title color */
}

.service-description {
  color: #666; /* Lighter description color */
}

.service-steps li::before {
  color: #D9CAB3; /* Match the color block accent */
}
</style>

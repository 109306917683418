<template>
  <div class="modal-overlay" @click="emitClose">
    <div class="modal-content" @click.stop v-if="serviceData"> 
      <div class="modal-header">
        <h2>{{ serviceData.title }}</h2> 
        <button @click="emitClose" class="close-button">&times;</button>
      </div>
      <div class="modal-body">
        <p>{{ serviceData.description }}</p>
        <h4>Nuestro Proceso:</h4>
        <ol>
          <li v-for="step in serviceData.steps" :key="step">{{ step }}</li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalComponent',
  props: ['serviceData'],
  methods: {
    emitClose() {
      this.$emit('close');
    }
  }
};
</script>
  
<style scoped>
/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Slightly darker overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto; /* Allow scrolling if content is too long */
}

/* Modal Content */
.modal-content {
  background-color: white;
  padding: 3rem;
  border-radius: 12px; /* More rounded corners */
  max-width: 80%;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Increased shadow depth */
  position: relative; /* For positioning the close button */
}

/* Modal Header */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.modal-header h2 {
  font-family: "Playfair Display", serif;
  font-size: 2rem;
  margin: 0;
}

.close-button {
  background: none;
  border: none;
  font-size: 2rem; /* Larger close button */
  cursor: pointer;
  color: #333;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

/* Modal Body */
.modal-body p {
  line-height: 1.6;
  margin-bottom: 2rem;
}

.modal-body h4 {
  font-family: "Montserrat", sans-serif; /* Heading font */
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #888; /* Slightly muted color */
}

.modal-body ol {
  list-style: none;
  counter-reset: step;
  padding-left: 0;
}

.modal-body ol li {
  position: relative;
  margin-bottom: 1rem;
  padding-left: 2.5rem; /* Indent list items */
}

.modal-body ol li::before {
  content: counter(step) ". ";
  counter-increment: step;
  position: absolute;
  left: 0;
  font-weight: bold;
}
</style>


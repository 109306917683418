<template>
  <footer>
    <p>&copy; 2024 Web Scraping Services. All rights reserved.</p>
    <nav>
      <router-link to="/legal">Privacy Policy</router-link>
      <router-link to="/legal">Terms of Service</router-link>
    </nav>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
};
</script>

<style scoped>
footer {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background-color: #f8f8f8;
}
nav {
  display: flex;
  gap: 1rem;
}
</style>

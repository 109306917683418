<template>
  <div>
    <section class="pricing">
      <h2>Pricing Plans</h2>
      <ul>
        <li>Basic Plan: \$100/month - Basic data extraction.</li>
        <li>Standard Plan: \$200/month - Data extraction + cleaning.</li>
        <li>Premium Plan: \$300/month - Full service including real-time updates and API integration.</li>
      </ul>
      <router-link to="/contact"><button>Request a Custom Quote</button></router-link>
    </section>
  </div>
</template>

<script>

export default {
  name: 'PricingPage',
};
</script>

<style scoped>
/* Add your styles here */
</style>

<template>
  <div>
    <section class="about">
      <h2>About Us</h2>
      <p>We provide top-notch web scraping services to help you gather the data you need.</p>
      <p>Our mission is to deliver accurate, timely, and customized data solutions to empower businesses to make data-driven decisions.</p>
    </section>
  </div>
</template>

<script>
export default {
  name: 'AboutPage',
};
</script>

<style scoped>
/* Add your styles here */
</style>

<template>
  <nav class="navbar">
    <div class="container">
      <div class="logo-and-name-container"> 
        <router-link to="/" class="navbar-brand logo-container">
          <img src="../assets/logo.png" alt="Your Logo" class="logo">
          <h3 class="navbar-brand">Hawk data</h3> 
        </router-link>
      </div>
      <ul class="navbar-nav">
        <li class="nav-item">
          <router-link to="/services" class="nav-link">services</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/contact" class="nav-link">contact</router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>
<script>
export default {
  name: 'HeaderComponent',
};
</script>

<style scoped>
/* Navbar Styling */
.navbar {
  background-color: #F8F4F1; /* Use the exact color from the reference */
  padding: 1rem 0;  
  font-family: "Montserrat", sans-serif;
}

.container {
  display: flex;
  justify-content: space-between; 
  align-items: center;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo-and-name-container {
  display: flex;          /* Enable Flexbox for alignment */
  align-items: center;    /* Vertically align the logo and name */
  gap: 1rem;               /* Add some space between the logo and name */
}

.logo {
  height: 60px; 
}

.navbar-nav {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-item {
  margin-left: 2rem; /* Space between nav items */
}

.nav-link {
  font-size: 0.8rem;  /* Smaller font size */
  text-transform: uppercase; /* All caps */
  color: #333;
  text-decoration: none;
  transition: color 0.3s ease;
  padding: 0.5rem 0; /* Reduce padding */
}

.nav-link:hover {
  color: black; 
}

.nav-item:last-child .nav-link { /* Style for the last item */
  padding-right: 0; /* Remove right padding for the last item */
}

/* Media Query for Smaller Screens */
@media (max-width: 768px) {
  /* Adjust navbar styles for mobile view */
  .navbar-nav {
    flex-direction: column; 
    align-items: center;
  }

  .nav-item {
    margin: 0.5rem 0;
  }

  .nav-link {
    padding: 0.8rem 0;
  }
}
</style>

<template>
  <div>
    <section class="faqs">
      <h2>Frequently Asked Questions</h2>
      <ul>
        <li><strong>What is web scraping?</strong> Web scraping is the process of extracting data from websites.</li>
        <li><strong>Is web scraping legal?</strong> Yes, web scraping is legal as long as you comply with the terms of service of the websites you scrape.</li>
        <!-- Add more FAQs as needed -->
      </ul>
    </section>
  </div>
</template>

<script>
export default {
  name: 'FAQsPage',
};
</script>

<style scoped>
/* Add your styles here */
</style>

<template>
  <div>
    <section class="legal">
      <h2>Privacy Policy</h2>
      <p>Your privacy policy details go here...</p>
      <h2>Terms of Service</h2>
      <p>Your terms of service details go here...</p>
    </section>
  </div>
</template>

<script>

export default {
  name: 'LegalPage',
};
</script>

<style scoped>
/* Add your styles here */
</style>
